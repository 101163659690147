<template>
  <div>
    <el-dialog
      :append-to-body="true"
      v-if="dialog"
      :visible.sync="dialog"
      :title="isCheck ? '出佣审核' : '出佣信息'"
      width="800px"
      :before-close="cancel"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
            v-if="applyInfoShow"
            label="申请信息" name="first">
          <el-row>
            <el-col :span="8"><span class="label">申请人:</span> <span>{{ data.userName }}</span></el-col>

            <el-col :span="8"><span class="label">申请时间: </span> <span>{{ parseTime(data.createTime) }}</span>
            </el-col>
            <el-col :span="8"><span class="label">收款人姓名:</span> <span>{{ data.userName }}</span></el-col>
          </el-row>

         
          <el-row>
<!--            后面的是￥人民币金额,前面的是$美金金额-->
            <el-col :span="8"><span class="label">出佣金额: </span><span>${{ data.amount }}({{convertAmounts}})</span></el-col>
            <el-col :span="8"><span class="label">汇率: </span><span>{{ data.nominalExRate }}</span></el-col>
            <el-col :span="8"><span class="label">审核状态:</span> <span>{{ parseCommiStatus(data.chkStatus) }}</span>
            </el-col>
          </el-row>
          <div class="withdraw-info-apply">
            <el-row>
              <el-col><span  class="label">出佣方式: </span><span>{{ getPayType() }}</span></el-col>
            </el-row>
            <el-row
              v-if="ibTypeInfoFlag"
            >
              <el-col
                  :span="12"
              ><span class="label">MT4账号:</span> <span>{{ data.mt4Account }}</span></el-col>
            </el-row>
            <el-row v-if="currency!=''">
            <el-col :span="8"><span class="label">收款币种: </span><span>{{ currency }}</span></el-col>
          </el-row>
            <el-row v-if="bankApplyInfoFlag">
              <el-col
                      :span="12"
              ><span class="label">银行名称:</span> <span>{{ backInfo && backInfo.bankName }}</span></el-col>
              <el-col
                      :span="12"
              ><span class="label">银行帐号: </span> <span>{{backInfo && backInfo.bankAccount }}</span></el-col>
            </el-row>
            <el-row v-if="bankApplyInfoFlag">
              <el-col :span="12"><span class="label">银行网点:</span> <span>{{ backInfo && backInfo.bankOutletProvince}}-{{ backInfo && backInfo.bankOutletCity}}-{{ backInfo && backInfo.bankOutlet}}</span></el-col>
              <el-col :span="12"><span class="label">SwiftCode:</span> <span>{{ backInfo && backInfo.swiftCode }}</span></el-col>
            </el-row>
            <el-row v-if="otcApplyInfoFlag" class="row-box">
              <el-col :span="14" class="label">
                地址:
                <span class="address-span">{{otcInfo && otcInfo.address}}</span>
              </el-col>
              <el-col class="img-col" :span="10">
                <span class="label">二维码:</span>
                <el-image
                        v-if="otcInfo.qrPic"
                        class="qr-code img-el"
                        :src="otcInfo.qrPic"
                        :preview-src-list="[otcInfo.qrPic]"
                />
              </el-col>
            </el-row>
            <el-row v-if="usdtApplyInfoFlag">
              <el-col :span="14" class="label">
                地址: <span class="address-span">{{usdtInfo && usdtInfo.address}}</span>
              </el-col>
              <el-col class="img-col" :span="10">
                <span class="label">二维码:</span>
                <el-image
                        v-if="usdtInfo.qrPic"
                        class="qr-code img-el"
                        :src="usdtInfo.qrPic"
                        :preview-src-list="[usdtInfo.qrPic]"
                />
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
            v-if="applyerInfoShow"
            label="申请人信息"
            name="second"
            v-loading="loadingUser">
          <el-row>
            <el-col :span="8"><span class="label">用户名:</span> <span>{{ useInfo.userName ||' ' }}</span></el-col>
            <el-col :span="8"><span class="label">国籍: </span> <span>{{ useInfo.nationName}}</span>
            </el-col>
            <el-col :span="8"><span class="label">生日: </span> <span>{{useInfo.birthday}}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">电话号码: </span> <span>{{ useInfo.phone}}</span></el-col>
            <el-col :span="8"><span class="label">邮箱:</span> <span>{{ useInfo.email }}</span></el-col>
          </el-row>
          <el-row>
            <el-col><span class="label">家庭住址:</span> <span>{{useInfo.homeProvince }}-{{useInfo.homeCity }}-{{useInfo.homeStreet }}-{{useInfo.homeAddress }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
            v-if="auditLogShow"
            name="thirdly"
            label="审核记录">
          <el-table :data="events" size="small" stripe>
            <el-table-column prop="eventType" label="操作类型"/>
            <el-table-column prop="eventContent" label="审核意见">
              <template slot-scope="scope">
                <span>{{scope.row.eventContent || '-'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="eventPerson" label="操作人"/>
            <el-table-column prop="createTime" label="操作时间">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="转账凭证" width="150px" align="center">
              <template slot-scope="scope">
                <el-button v-model="imgUrl" type="primary" size="mini" class="my-button"
                           v-if="scope.row.transferVoucherImg" @click="toImg(scope.row.transferVoucherImg)">查看图片
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div v-if="isCheck" slot="footer" class="dialog-footer">
        <money-audit
            :style="{visibility: isWithdrawOperate ? 'hidden': 'visible'}"
            :tableStatus ="tableStatus"
            :isWithdrawOperate ="isWithdrawOperate"
            :withdrawChannelTypes = "withdrawChannelTypes"
            :data = "data"
            @handleSuccess = "handleSuccess"
            ref="moneyaudit"></money-audit>
        <el-button type="text" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit">通过</el-button>
      </div>
    </el-dialog>
    <el-dialog :append-to-body="true" :visible.sync="imgUrlShow" width="800px">
      <img :src="imgUrl" width="100%">
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile } from '@/api/data'
import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile,parseTime } from '@/utils/index'
import moneyAudit from '@/components/public/newMoneyAudit'
import withdrawInfo from '@/components/public/withdrawInfo'
import { audit } from '@/api/ib/ibMerchantCommission'
import checkPermission from '@/utils/permission'
import { parseCommiStatus, parseCommiType, parseCurrencyType } from '@/views/pt/ib/utils/index'
import { initData } from '@/api/data';
import { tPayFeeConfig } from '@/api/cus/tCustDepositWithdraw'

  export default {
    components:{
      withdrawInfo,
      moneyAudit,
    },
    props: {
      isCheck: {
        type: Boolean,
        required: true
      },
      tableStatus: {
        type: Number,
        required: true
      },
      sup_this: {
        type: Object,
        default: null
      },
      data: {
        type: Object,
        default: null
      },
      refresh: {
        type: Boolean,
        default: false
      },
      isWithdrawOperate: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      let _this = this;
      return {
        fileList: [],
        transferVoucherImg: '',
        imgUrl: '',
        imgUrlShow: false,
        loadingUser: false,
        loading: false,
        dialog: false,
        events: null,
        useInfo: {
          collections:[]
        },
        activeName: checkPermission(['ADMIN','COMMISSION_APPLY_INFO']) ? 'first' : checkPermission(['ADMIN','COMMISSION_CUST_INFO']) ? 'second': 'thirdly',
        payType: 1,
        payChannel: '',
        withdrawChannelTypes: [],
      }
    },
    watch:{
      dialog(newValue){
        if((this.applyInfoShow == false && this.applyerInfoShow) && newValue){
          this.loadingUser = true;
          this.getApplyerInfo();
        }
      }
    },
    computed:{
      applyInfoShow(){
        return checkPermission(['ADMIN','COMMISSION_APPLY_INFO']);
      },
      applyerInfoShow(){
        return checkPermission(['ADMIN','COMMISSION_CUST_INFO'])
      },
      auditLogShow(){
        return checkPermission(['ADMIN','COMMISSION_AUDIT_INFO']);
      },
      // currency(){
      //   console.log(this.data);
      //   let type = this.data.type;
      //   let data = this.data.snapshotList.filter(obj => obj.payTypeId == type);
      //   return data[0].custPayDetail.currency;
      // },
      backInfo(){
        if(this.data){
          if(this.data.snapshotList){
            for (let i = 0; i < this.data.snapshotList.length; i++) {
              if(this.data.snapshotList[i].payTypeId == 7){
                return this.data.snapshotList[i].custPayDetail;
                break;
              }
            }
          }
        }else {
          return {
            bankAccount: '',
            bankName: '',
            bankOutlet: '',
            swiftCode: '',
          }
        }
      },
      usdtInfo(){
        if(this.data){
          if(this.data.snapshotList){
            for (let i = 0; i < this.data.snapshotList.length; i++) {
              if(this.data.snapshotList[i].payTypeId == 8){
                return this.data.snapshotList[i].custPayDetail;
                break;
              }
            }
          }
        }else {
          return {
            address: '',
            qrPic: '',
          }
        }
      },
      otcInfo(){
        if(this.data){
          if(this.data.snapshotList){
            for (let i = 0; i < this.data.snapshotList.length; i++) {
              if(this.data.snapshotList[i].payTypeId == 9){
                return this.data.snapshotList[i].custPayDetail;
                break;
              }
            }
          }
        }else {
          return {
            address: '',
            qrPic: '',
          }
        }
      },
      ibTypeInfoFlag(){
        return this.data.type == 0;
      },
      // 出佣
      bankApplyInfoFlag(){
        return this.data.type == 7;
      },
      otcApplyInfoFlag(){
        return this.data.type == 9;
      },
      usdtApplyInfoFlag(){
        return this.data.type == 8;
      },
      convertAmounts(){
        let currencyname = "";
        if(this.data.snapshotList){
          let type = this.data.type;
          //8  USDT  9  ChipPay
          if(type == 8 || type == 9){
              return  "USD" + (this.data.amount * this.data.nominalExRate).toFixed(2);
          }
          if(type == 0){
              return  "" + (this.data.amount * this.data.nominalExRate).toFixed(2);
          }
          let data = this.data.snapshotList.filter(obj => obj.payTypeId == type);
          currencyname = data[0].custPayDetail.currency;
        }
        if(currencyname == "CNY"){
          return  "¥" + (this.data.amount * this.data.nominalExRate).toFixed(2);
        }else if(currencyname == "VND"){
          return  "₫" + (this.data.amount * this.data.nominalExRate).toFixed(2);
        }else if(currencyname == "USD"){
          return  "$" + (this.data.amount * this.data.nominalExRate).toFixed(2);
        }
      },
      currency(){
        let currencyname = "";
        if(this.data.snapshotList){
          let type = this.data.type;
          if(type == 8 || type == 9){
               return  "USD";
          }
          if(type == 0){
              return  "";
          }
          let data = this.data.snapshotList.filter(obj => obj.payTypeId == type);
          currencyname = data[0].custPayDetail.currency;
        }
        return currencyname;
      }
    },
    methods: {
      getLimitUploadImageFormat,
      parseTime,
      checkPermission,
      parseCommiStatus,
      parseCommiType,
      parseCurrencyType,
      cancel() {
        this.useInfo = { collections:[] };
        this.dialog = false;
        this.loading = false;
        this.activeName = this.applyInfoShow ? 'first' : this.applyerInfoShow ? 'second' : 'thirdly';
      },
      getPayType(){
        switch (this.data.type) {
          case 0:
            return 'IB转账';
          case 7:
            return '出佣到银行卡';
          case 8:
            return '出佣到USDT';
          case 9:
            return '出佣到OTC365';
        }
      },
      getPayFeeConfig(){
        const params = {
          oprType: 2,
          isAdmin:1,
          status:1,
          payType: this.data.payTypeId,
          currency:this.currency
        };
        if(this.data.riskControlFlag == 1){
          params.riskControlFlag = 0;
        }
        tPayFeeConfig(params).then(res => {
          this.withdrawChannelTypes = res.content;
          }).catch(err => {
        })
      },
      handleClick(tab, event) {
        if (tab.name == 'second' && !this.useInfo.id) {
          this.loadingUser = true;
          this.getApplyerInfo();
        }
      },
      getApplyerInfo(){
        var url1 = 'crm/tCustInfo';
        var params1 = { id: this.data.merchantId, page: 0, size: 10, }
        initData(url1, params1).then(res => {
          this.useInfo = res.content[0];
          this.loadingUser = false;
        }).catch(err => {
          this.loadingUser = false;
          console.log(err.response);
        })
      },
      beforeUpload(file) {
        return limitImageSizeFormat(file,this);
      },
      handleSuccess(data) {
        this.transferVoucherImg = data;
      },
      handlePreview(file) {
        console.log(file)
      },
      toImg(imgUrl){
        this.imgUrl = imgUrl;
        this.imgUrlShow = true;
      },
      doSubmit() {
        this.payType = this.$refs.moneyaudit.withdrawType;
        this.payChannel = this.$refs.moneyaudit.withdrawChannel;
        const params = {
          id: this.data.id,
          merchantId: this.data.merchantId,
          decision:this.$refs.moneyaudit.auditResult,
          auditBy: this.data.chkStatus
        }
        if(this.$refs.moneyaudit.auditResult ==2){
          params.rejectReason = this.$refs.moneyaudit.rejectReason;
          if(this.$refs.moneyaudit.rejectReason == -1){
            params.rejectReason = this.$refs.moneyaudit.rejectReasonSelf;
          }
        }
        if (this.transferVoucherImg) {
          params['transferVoucherImg'] = this.transferVoucherImg
        };

        /*在出佣-财务部出佣/出佣失败重新出佣环节新增参数：
payoutType 出佣方式 1:手工 2:自动（仅在出佣-财务部出金环节传此参数）
payType 支付方式（仅在出佣-财务部出佣环节传此参数）*/
        if(this.tableStatus == 5 || this.isWithdrawOperate){
          params.payoutType = this.payType;

          if(this.payType == 2&&this.$refs.moneyaudit.auditResult !=2){
            if(!this.payChannel){
              this.$message.error('请选择渠道');
              return;
            }
            params.payType = this.payChannel;

          }
        }
        this.loading = true;
        audit(params).then(res => {
          this.$notify({
            title: res,
            type: 'success',
            duration: 2500
          })
          this.loading = false;
          this.dialog = false;
          this.cancel();
          this.sup_this.init();
        }).catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        })
      },
    }
  }
</script>

<style scoped>
  .el-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }

  .el-col {
    border-radius: 4px;
  }

  .label {
    font-size: 18px;
    padding: 10px 10px;
  }
</style>
<style lang="less" scoped>
  .withdraw-info-apply {
    background-color: #FAFAFA;
    .img-el {
      width: 100px;
      height: 100px;
    }
    .img-col {
      display: flex;
      align-items: center;
    }
  }
  .applyer-label {
    display: inline-block;
    font-size: 18px;
    width: 100px;
  }
</style>
